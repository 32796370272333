import React from "react";
import Typography from "@mui/material/Typography";
import { makeStyles } from "tss-react/mui";
import Box from "@mui/material/Box";

import { Grid } from "@mui/material";
import { IItem } from "../interfaces/Restaurant";

const useStyles = makeStyles()(() => ({
  container: {
    display: "flex",
  },

  menuItem: {
    padding: "15px",
    borderBottom: "1px solid rgba(0, 0, 0, 0.1)",
  },

  itemTitle: {
    fontWeight: "bold",
  },

  itemDetails: {
    fontStyle: "italic",
  },

  antiButton: {
    "&:hover": {
      backgroundColor: "transparent",
      cursor: "default",
    },
    disabled: true,
    color: "#000",
  },
}));

interface MenuItemDetailsProps {
  highlights: string[];
  isHidden: boolean;
  item: IItem;
  restaurantId: string;
  likesDict: any;
  bookmarksDict: any;
}

export default function MenuItemDetails({
  highlights,
  isHidden,
  item,
}: MenuItemDetailsProps) {
  const { classes } = useStyles();

  return item.render ? (
    <Grid
      className={classes.menuItem}
      hidden={isHidden}
      item
      xs={12}
      sm={12}
      md={12}
      lg={6}
    >
      <Box>
        <Grid container>
          <Grid item xs={11}>
            <span>
              <Typography className={classes.itemTitle}>
                <strong>{item.name}</strong>
              </Typography>
            </span>
          </Grid>
          <Grid item xs={1} justifyContent="flex-end">
            {item.price ? (
              <Typography gutterBottom align="right" variant="subtitle1">
                {item.price}
              </Typography>
            ) : (
              <Typography gutterBottom variant="subtitle1">
                {""}
              </Typography>
            )}
          </Grid>
          {item.multiprice && (
            <Grid item xs={12} justifyContent="flex-end">
              <span>
                <Typography
                  gutterBottom
                  variant="subtitle2"
                  className={classes.itemDetails}
                >
                  {item.multiprice?.description}
                </Typography>
                <Typography
                  gutterBottom
                  variant="subtitle2"
                  className={classes.itemDetails}
                >
                  {/* ... */}
                </Typography>
              </span>
            </Grid>
          )}
          {item.add && (
            <Grid item xs={12} justifyContent="flex-end">
              <Typography gutterBottom variant="subtitle2">
                {item.add.map((a) => {
                  return (
                    <li key={a.description}>
                      {a.description}: {a.price}
                    </li>
                  );
                })}
              </Typography>
            </Grid>
          )}

          <Grid item xs={12}>
            <span>
              <Typography gutterBottom>{item.description}</Typography>
            </span>
          </Grid>

          {item.vegan ? (
            <Grid item xs>
              <Typography variant="caption" gutterBottom>
                {"Vegan "}
              </Typography>
            </Grid>
          ) : null}
          {item.vegetarian ? (
            <Grid item xs>
              <Typography variant="caption" gutterBottom>
                {"Vegetarian "}
              </Typography>
            </Grid>
          ) : null}
          {item.glutenFree ? (
            <Grid item xs>
              <Typography variant="caption" gutterBottom>
                {"Gluten Free "}
              </Typography>
            </Grid>
          ) : null}
          {item.dairyFree ? (
            <Grid item xs>
              <Typography variant="caption" gutterBottom>
                {"Dairy Free "}
              </Typography>
            </Grid>
          ) : null}
        </Grid>
        {/* new row */}

        {highlights && highlights.length > 0 ? (
          <Grid item xs={12}>
            <Typography variant="body1" gutterBottom className={"highlight"}>
              {"Matched search: "}
              <Box component="span" fontWeight="fontWeightBold">
                {highlights.map((highlight) => highlight + " ")}
              </Box>
            </Typography>
          </Grid>
        ) : null}
      </Box>
    </Grid>
  ) : (
    <></>
  );
}
