import React, { useState } from "react";
import { SearchChip } from "../../interfaces/Restaurant";
import { useDispatch, useSelector } from "react-redux";
import {
  getDisabledSearchChipCount,
  getSearchChips,
  setDisabledSearchChipCount,
  setSearchChips,
} from "../../redux/search";
import SearchComponent from "./SearchComponent";

export default function InclusiveSearch() {
  const [searchInput, setSearchInput] = useState("");
  const handleChangeFilter = (event: any) => {
    setSearchInput(event.target.value);
  };

  const dispatch = useDispatch();
  const searchChips = useSelector(getSearchChips);
  const disabledSearchChipCount = useSelector(getDisabledSearchChipCount);
  const createSearchChip = (value: any) => {
    const chip = {
      enabled: true,
      value: value,
    };
    return chip;
  };

  const handleSearchChipClick = (clickedChip: any) => {
    clickedChip.enabled = !clickedChip.enabled;
    const newChips = searchChips.map((c: SearchChip) => {
      return c.value === clickedChip.value ? clickedChip : c;
    });
    if (!clickedChip.enabled) {
      dispatch(setDisabledSearchChipCount(disabledSearchChipCount + 1));
    } else {
      dispatch(setDisabledSearchChipCount(disabledSearchChipCount - 1));
    }
    dispatch(setSearchChips(newChips));
  };
  const handleSearchChipDelete = (deletedChip: any) => {
    const newChips = searchChips?.filter((c: any) => {
      return c.value !== deletedChip.value;
    });
    if (deletedChip.enabled === false) {
      dispatch(setDisabledSearchChipCount(disabledSearchChipCount - 1));
    }
    dispatch(setSearchChips(newChips));
  };

  const handleKeyDownFilter = (event: any) => {
    if (event.key === "Enter" && searchInput.length > 0) {
      const newChips: SearchChip[] = [
        ...searchChips,
        createSearchChip(searchInput),
      ];
      dispatch(setSearchChips(newChips));
      setSearchInput("");
      event.target.blur();
    }
  };

  return (
    <SearchComponent
      searchInput={searchInput}
      setSearchInput={setSearchInput}
      handleChangeFilter={handleChangeFilter}
      handleKeyDownFilter={handleKeyDownFilter}
      searchChips={searchChips}
      handleSearchChipClick={handleSearchChipClick}
      handleSearchChipDelete={handleSearchChipDelete}
      label="Filter the menu"
      placeholder="example: chicken"
    />
  );
}
