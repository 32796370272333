import React from "react";
import { makeStyles } from "tss-react/mui";

import { Paper, Grid } from "@mui/material";
import MenuItem from "./MenuItem";
import { IItem } from "../interfaces/Restaurant";
import { useParams } from "react-router-dom";
import useRestaurantData from "../hooks/useRestaurantData";

const useStyles = makeStyles()(() => ({
  menuSection: {
    padding: "20px",
    marginBottom: "20px",
    backgroundColor: "#fbfbf8",
  },
  menuSectionH6: {
    fontWeight: "bold",
    fontSize: "1.5rem",
    marginBottom: "10px",
  },
  menuSectionNote: {
    fontStyle: "italic",
    marginBottom: "15px",
  },
}));

interface MenuSectionProps {
  likesDict: any;
  bookmarksDict: any;
  menuSection: any;
}

export default function MenuSection({
  likesDict,
  bookmarksDict,
  menuSection,
}: MenuSectionProps) {
  const { restaurantId = "" } = useParams();
  const { classes } = useStyles();

  const getHighlights = (name: string) => {
    return [];
  };

  return (
    <Paper
      className={classes.menuSection}
      key={menuSection.name}
      square
      elevation={4}
    >
      {<h3 className={classes.menuSectionH6}>{menuSection.name}</h3>}
      {menuSection.note && (
        <p className={classes.menuSectionNote}>{menuSection.note} </p>
      )}
      <Grid container direction="row">
        {menuSection?.menuItems?.map((item: IItem) => {
          return (
            <MenuItem
              key={item.name}
              isHidden={false}
              highlights={getHighlights(item.name)}
              item={item}
              likesDict={likesDict}
              bookmarksDict={bookmarksDict}
              restaurantId={restaurantId}
            />
          );
        })}
      </Grid>
    </Paper>
  );
}
