import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { SearchChip } from "../interfaces/Restaurant";

import { RootState } from "./config";

interface SearchState {
  searchChips: SearchChip[];
  antiSearchChips: SearchChip[];
  loading: "idle" | "pending" | "done" | "error" | "";
  disabledSearchChipsCount: number;
  disabledAntiSearchChipsCount: number;
}

// The initial state of the Restaurant component
export const initialState: SearchState = {
  searchChips: [],
  antiSearchChips: [],
  disabledSearchChipsCount: 0,
  disabledAntiSearchChipsCount: 0,
  loading: "idle",
};

const searchSlice = createSlice({
  name: "search",
  initialState,
  reducers: {
    setSearchChips(
      state: SearchState,
      { payload }: PayloadAction<SearchChip[]>
    ) {
      state.searchChips = payload;
    },
    setDisabledSearchChipCount(
      state: SearchState,
      { payload }: PayloadAction<number>
    ) {
      state.disabledSearchChipsCount = payload;
    },
    setAntiSearchChips(
      state: SearchState,
      { payload }: PayloadAction<SearchChip[]>
    ) {
      state.searchChips = payload;
    },
    setDisabledAntiSearchChipCount(
      state: SearchState,
      { payload }: PayloadAction<number>
    ) {
      state.disabledAntiSearchChipsCount = payload;
    },
  },
});

export const getSearchChips = (state: RootState): SearchChip[] => {
  return state.search.searchChips;
};

export const getSearchChipCount = (state: RootState): number => {
  return state.search.searchChips.length;
};
export const getDisabledSearchChipCount = (state: RootState): number => {
  return state.search.disabledSearchChipsCount;
};

export const getAntiSearchChips = (state: RootState): SearchChip[] => {
  return state.search.antiSearchChips;
};

export const getAntiSearchChipCount = (state: RootState): number => {
  return state.search.antiSearchChips.length;
};
export const getDisabledAntiSearchChipCount = (state: RootState): number => {
  return state.search.disabledAntiSearchChipsCount;
};

export const {
  setSearchChips,
  setAntiSearchChips,
  setDisabledSearchChipCount,
  setDisabledAntiSearchChipCount,
} = searchSlice.actions;

export const { name, actions, reducer } = searchSlice;

export default searchSlice.reducer;
