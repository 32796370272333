import React from "react";

import { useEffect, useState, useCallback } from "react";

import Geocode from "react-geocode";

import HaversineDistance from "./HaversineDistance";
import GoogleMaps from "./GoogleMaps";

export default function MapsContainer(props) {
  const [destination, setDestination] = useState({});
  const [loading, setLoading] = useState(true);

  const getDestination = useCallback(() => {
    Geocode.fromAddress(props.address).then(
      (response) => {
        const { lat, lng } = response.results[0].geometry.location;
        setDestination({ latitude: lat, longitude: lng });
        console.log(
          `Destination gathered : {${lat}, ${lng}} Consider adding this to DB to reduce requests to google`
        );
      },
      (error) => {
        console.error(error);
      }
    );
  }, [props.address]);

  useEffect(() => {
    Geocode.setApiKey(process.env.REACT_APP_MAPS_API_KEY);
    Geocode.setLanguage("en");
    Geocode.setRegion("es");
    Geocode.enableDebug();

    setLoading(false);
  }, []);

  useEffect(() => {
    if (!loading)
      props.coords ? setDestination(props.coords) : getDestination();
  }, [loading, props.coords, getDestination]);

  return (
    <div>
      {destination && destination.longitude && destination.latitude ? (
        <div>
          <GoogleMaps destination={destination} />
          <HaversineDistance destination={destination} />
        </div>
      ) : null}
    </div>
  );
}
