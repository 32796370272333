import React from "react";
import { Grid, Divider, Box, Paper } from "@mui/material";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import useGlobalSearch from "../hooks/UseGlobalSearch";
import { getAntiSearchChips, getSearchChips } from "../redux/search";
import InclusiveSearch from "./search/InclusiveSearch";
import useRestaurantData from "../hooks/useRestaurantData";
import { SearchChip } from "../interfaces/Restaurant";

export default function MenuSearch() {
  const { restaurantId = "" } = useParams();
  const { restaurant } = useRestaurantData(restaurantId);

  return (
    <>
      <Paper elevation={1} square>
        <Box p={2}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <InclusiveSearch />
            </Grid>
            {/* <Grid item xs={12} sm={6} md={4} lg={3}>
              <Typography variant="h6" component="h3" gutterBottom>
                Exclusive Search
              </Typography>
              <ExclusiveSearch />
            </Grid> */}
          </Grid>
        </Box>
      </Paper>
      <Divider style={{ backgroundColor: "#ccc" }} variant="middle" />
    </>
  );
}
