import { useEffect, useState } from "react";
import { IItem, IMenu, IRestaurant, ISection } from "../interfaces/Restaurant";

const useGlobalSearch = (searchString: string, restaurant: IRestaurant) => {
  const [updatedRestaurant, setUpdatedRestaurant] = useState(restaurant);

  useEffect(() => {
    const searchTerms = searchString
      .split(" ")
      .map((word) => word.toLowerCase());

    const newRestaurant = JSON.parse(JSON.stringify(restaurant)); // Deep copy the restaurant object

    newRestaurant.menus.forEach((menu: IMenu) => {
      menu.menuSections.forEach((menuSection: ISection) => {
        const sectionMatch = searchTerms.some(
          (term) =>
            menuSection.name.toLowerCase().includes(term) ||
            (menuSection.note && menuSection.note.toLowerCase().includes(term))
        );

        menuSection.menuItems.forEach((menuItem: IItem) => {
          menuItem.render =
            searchString === "" ||
            sectionMatch || // If section name or note matches
            menu.name.toLowerCase().includes(searchString.toLowerCase()) || // If menu name matches
            searchTerms.some(
              (term) =>
                menuItem.name.toLowerCase().includes(term) ||
                (menuItem.description &&
                  menuItem.description.toLowerCase().includes(term))
            );
        });
      });
    });

    setUpdatedRestaurant(newRestaurant);
  }, [searchString, restaurant]);

  return updatedRestaurant;
};

export default useGlobalSearch;
