import React, { useEffect } from "react";
import { makeStyles } from "tss-react/mui";
import InputBase from "@mui/material/InputBase";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Paper, Autocomplete } from "@mui/material";
import { getAllRestaurants } from "../api";

import useGeolocation from "../hooks/useGeolocation";

const useStyles = makeStyles()((theme) => ({
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  inputRoot: {
    color: "inherit",
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "20ch",
    },
  },
}));

export default function RestaurantSearch() {
  const { classes } = useStyles();
  const [restaurants, setRestaurants] = React.useState(null);

  const navigate = useNavigate();

  const { loaded, coordinates, error } = useGeolocation();

  useEffect(() => {
    async function fetchData() {
      const restaurants = await getAllRestaurants(coordinates);
      setRestaurants(restaurants.data.data);
    }
    if (coordinates.lat && coordinates.lng) fetchData();
  }, [coordinates]);

  return (
    <form
      onSubmit={() => {
        // console.log('submit: ', event.target.value);
      }}
    >
      <Autocomplete
        freeSolo
        autoHighlight={true}
        id="restaurant-search"
        onChange={(event, value) => {
          navigate(`/restaurants/${value.name}/${value.id}`);
        }}
        disableClearable
        options={
          restaurants?.map((restaurant) => ({
            key: restaurant._id,
            label: restaurant.name,
            name: restaurant.name,
            id: restaurant._id,
            address1: restaurant.address1,
          })) || []
        }
        PaperComponent={({ children, ...props }) => (
          <Paper {...props} sx={{ width: 300 }}>
            {children}
          </Paper>
        )}
        sx={{ width: 300 }}
        getOptionLabel={(option) => `${option.name} - ${option.address1}`}
        renderInput={(params) => (
          <InputBase
            placeholder="Find a restaurant..."
            ref={params.InputProps.ref}
            inputProps={params.inputProps}
            autoFocus
            sx={{ width: 300 }}
            classes={{
              root: classes.inputRoot,
              input: classes.inputInput,
            }}
          />
        )}
      />
    </form>
  );
}
