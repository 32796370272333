const mapsSelector = (lat, long) => {
  if (
    /* if we're on iOS, open in Apple Maps */
    navigator.userAgentData.platform.indexOf("iPhone") !== -1 ||
    navigator.userAgentData.platform.indexOf("iPod") !== -1 ||
    navigator.userAgentData.platform.indexOf("iPad") !== -1
  )
    window.open(`maps://maps.google.com/maps?daddr=${lat},${long}&amp;ll=`);
  /* else use Google */ else
    window.open(`https://maps.google.com/maps?daddr=${lat},${long}&amp;ll=`);
};

module.exports = {
  mapsSelector,
};
