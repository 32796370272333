import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import RestaurantMenuTabs from "../components/RestaurantMenuTabs";
import RestaurantDetails from "../components/RestaurantDetails";
import { useDispatch } from "react-redux";
import { styled } from "@mui/system";

import useRestaurantData from "../hooks/useRestaurantData";

const Container = styled("div")({
  maxWidth: "1280px",
  margin: "auto",
  padding: "40px 20px",
  backgroundColor: "#f5f5f5",
  borderRadius: "8px",
  boxShadow: "0 2px 8px rgba(0, 0, 0, 0.1)",
});

function Restaurant() {
  const { restaurantId } = useParams();

  const { restaurant } = useRestaurantData(restaurantId);

  return (
    <Container>
      {restaurant ? (
        <>
          <RestaurantDetails restaurant={restaurant} />
          <RestaurantMenuTabs restaurant={restaurant} />
        </>
      ) : null}
    </Container>
  );
}

export default Restaurant;
