import React from "react";

import { useEffect, useState } from "react";
import haversine from "haversine-distance";
import { Typography } from "@mui/material";

export default function HaversineDistance(props) {
  const [distance, setDistance] = useState(null);
  const [start, setStart] = useState(null);

  useEffect(() => {
    if (props.destination) {
      if (!start) {
        navigator.geolocation.getCurrentPosition(function (position) {
          setStart({
            latitude: position.coords.latitude,
            longitude: position.coords.longitude,
          });
        });
      }
    }
  }, [props.destination, start]);

  useEffect(() => {
    if (start && start.latitude)
      setDistance(haversine(start, props.destination));
  }, [props.destination, start]);

  return (
    <div>
      {distance ? (
        <div>
          <Typography variant="body2">
            Distance: {Math.round(distance * 0.000621371 * 100) / 100} Mi{" "}
            {Math.round(distance * 0.001 * 100) / 100} Km
          </Typography>
        </div>
      ) : (
        "Share your location to calculate distance. "
      )}
    </div>
  );
}
