import React from "react";
import { GoogleMap, LoadScript, Marker } from "@react-google-maps/api";
const GoogleMaps = (props) => {
  const mapStyles = {
    height: "40vh",
    width: "100%",
  };
  const defaultCenter = {
    lat: props.destination.latitude,
    lng: props.destination.longitude,
  };

  const locations = [
    {
      name: "Food",
      location: {
        lat: props.destination.latitude,
        lng: props.destination.longitude,
      },
    },
  ];

  return (
    <LoadScript googleMapsApiKey={process.env.REACT_APP_MAPS_API_KEY}>
      <GoogleMap mapContainerStyle={mapStyles} zoom={11} center={defaultCenter}>
        {locations.map((item) => {
          return <Marker key={item.name} position={item.location} />;
        })}
      </GoogleMap>
    </LoadScript>
  );
};
export default GoogleMaps;
