import React, { useEffect } from "react";

import { makeStyles } from "tss-react/mui";

import { Paper, Grid, Box } from "@mui/material";
import MenuItem from "./MenuItem";
import { IItem, IMenu, ISection } from "../interfaces/Restaurant";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { Item } from "semantic-ui-react";
import MenuSection from "./MenuSection";
import { AnyAsyncThunk } from "@reduxjs/toolkit/dist/matchers";

interface MenuComponentProps {
  restaurant: any;
  menu: any;
  likesDict: any;
  bookmarksDict: any;
  activeTabIndex: any;
}

export default function MenuComponent({
  menu,
  likesDict,
  bookmarksDict,
  activeTabIndex,
}: MenuComponentProps) {
  return (
    <div key={menu.name} hidden={activeTabIndex !== menu.name}>
      {menu.menuSections?.map((menuSection: ISection) => {
        return (
          <MenuSection
            key={menuSection.name}
            likesDict={likesDict}
            bookmarksDict={bookmarksDict}
            menuSection={menuSection}
          />
        );
      })}
    </div>
  );
}
