import "./App.scss";
import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

import HomePage from "../pages/HomePage";
import Restaurant from "../pages/Restaurant";

import "bootstrap/dist/css/bootstrap.min.css";
import PrimarySearchAppBar from "../components/PrimarySearchAppBar";

function App() {
  return (
    <div className="App" style={{ backgroundColor: "#fbfbf8" }}>
      <Router>
        <PrimarySearchAppBar />
        <Routes>
          <Route
            path="/restaurants/:restaurantName/:restaurantId"
            exact
            element={<Restaurant />}
          />
          <Route path="/" exact element={<HomePage />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
