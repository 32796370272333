import { useState, useEffect } from "react";
import api from "../api";
import { IRestaurant } from "../interfaces/Restaurant";

const useRestaurantData = (id: string) => {
  const [restaurant, setRestaurant] = useState<IRestaurant | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<Error | null>(null);

  useEffect(() => {
    const fetchRestaurantData = async () => {
      try {
        setLoading(true);
        const restaurantData = await api.getRestaurantById(id);

        setRestaurant(restaurantData.data.data);
      } catch (e: any) {
        setError(e);
      } finally {
        setLoading(false);
      }
    };
    if (id) {
      fetchRestaurantData();
    }
  }, [id]);

  return { restaurant, loading, error };
};

export default useRestaurantData;
