import React, { useState } from 'react'

export const UserContext = React.createContext();
// export const SearchContext = React.createContext();

const Store = ({children}) => {
    const [user, setUser] = useState(null);
    // const [searchInput, setSearchInput] = useState(null);
    //need to set the user here... derp
    return(
        <UserContext.Provider value={[user, setUser,]}> 
        {/* <SearchContext.Provider value={[searchInput, setSearchInput,]}>  */}
            {children}
        {/* </SearchContext.Provider> */}
        </UserContext.Provider>
    )
}
export default Store;