import React, { useEffect, useState } from "react";
import MapsContainer from "./MapsContainer";
import { Box, Button, Grid, Typography } from "@mui/material";
import { styled } from "@mui/system";
import { mapsSelector } from "./utils/mapsSelector";

import { IRestaurant } from "../interfaces/Restaurant";
import CallIcon from "@mui/icons-material/Call";
import DirectionsIcon from "@mui/icons-material/Directions";
import LanguageIcon from "@mui/icons-material/Language";

const Title = styled(Typography)({
  width: "100%",
  height: 80,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  backgroundColor: "#fbfbf8",
  fontSize: "1.5rem",
  fontWeight: "bold",
  marginBottom: "16px",
});

const StyledButton = styled(Button)({
  width: "100%",
  justifyContent: "flex-start",
  textTransform: "none",
  fontWeight: "bold",
  padding: "8px 16px",
  borderRadius: "4px",
  backgroundColor: "#f0f0f0",
  "&:hover": {
    backgroundColor: "#e0e0e0",
  },
});

interface RestaurantDetailsProps {
  restaurant: IRestaurant;
}

function RestaurantDetails({ restaurant }: RestaurantDetailsProps) {
  const [openMap, setOpenMap] = useState(false);
  const toggleOpen = () => {
    setOpenMap(!openMap);
  };

  useEffect(() => {
    const scrollOnLoad = () => {
      window.scrollBy(0, 56);
    };
    window.addEventListener("load", scrollOnLoad);
    return () => window.removeEventListener("load", scrollOnLoad);
  }, []);

  return (
    <Box>
      <Grid item xs={12}>
        <Title>{restaurant.name}</Title>
      </Grid>
      <Grid container spacing={1}>
        <Grid item xs={4}>
          {restaurant && restaurant.phone ? (
            <StyledButton href={`tel:${restaurant.phone}`}>
              <CallIcon />
              Call
            </StyledButton>
          ) : null}
        </Grid>
        <Grid item xs={4}>
          {restaurant && restaurant.address1 ? (
            <StyledButton
              onClick={() =>
                mapsSelector(
                  restaurant.location.latitude,
                  restaurant.location.longitude
                )
              }
            >
              <DirectionsIcon />
              Directions
            </StyledButton>
          ) : null}
        </Grid>
        <Grid item xs={4}>
          {restaurant && restaurant.website ? (
            <StyledButton href={restaurant.website}>
              <LanguageIcon />
              Website
            </StyledButton>
          ) : null}
        </Grid>
        <Grid item xs={12}>
          <StyledButton onClick={toggleOpen}>Toggle Map</StyledButton>
        </Grid>
        <Grid item xs={12}>
          {openMap && (
            <MapsContainer
              address={restaurant.address1}
              coords={restaurant.location}
            />
          )}
        </Grid>
      </Grid>
    </Box>
  );
}

export default RestaurantDetails;
