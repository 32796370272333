import axios from "axios";

const api = axios.create({
  // baseURL: "http://localhost:5000/api",
  baseURL: "https://simplefoodfinder.herokuapp.com/api",
});
//restaurant
export const insertRestaurant = (payload) => api.post(`/restaurant`, payload);
export const getAllRestaurants = (userLocation) => {
  console.log(`/restaurants/lat=${userLocation.lat}&long=${userLocation.lng}`);
  return api.get(
    `/restaurants?lat=${userLocation.lat}&long=${userLocation.lng}`
  );
};
export const updateRestaurantById = (id, payload) =>
  api.put(`/restaurant/${id}`, payload);
export const deleteRestaurantById = (id) => api.delete(`/restaurant/${id}`);
// export const getRestaurantById = id => api.get(`/restaurant/${id}`)
export const getRestaurantById = (restaurantId) =>
  api.get(`/restaurant/${restaurantId}`);
//menu
export const insertMenu = (payload) => api.post(`/menu`, payload);
export const getAllMenus = () => api.get(`/menus`);
export const updateMenuById = (id, payload) => api.put(`/menu/${id}`, payload);
export const deleteMenuById = (id) => api.delete(`/menu/${id}`);
export const getMenuById = (id) => api.get(`/menu/${id}`);
export const getAllMenusByRestaurantId = (restaurantId) =>
  api.get(`/menus/${restaurantId}`);
//menuSection
export const insertMenuSection = (payload) => api.post(`/menuSection`, payload);
export const getAllMenuSections = () => api.get(`/menuSections`);
export const updateMenuSectionById = (id, payload) =>
  api.put(`/menuSection/${id}`, payload);
export const deleteMenuSectionById = (id) => api.delete(`/menuSection/${id}`);
export const getMenuSectionById = (id) => api.get(`/menuSection/${id}`);
export const getAllMenuSectionsByRestaurantId = (restaurantId) =>
  api.get(`/menuSections/${restaurantId}`);
//menuItem
export const insertMenuItem = (payload) => api.post(`/menuItem`, payload);
export const getAllMenuItems = () => api.get(`/menuItems`);
export const updateMenuItemById = (id, payload) =>
  api.put(`/menuItem/${id}`, payload);
export const deleteMenuItemById = (id) => api.delete(`/menuItem/${id}`);
export const getMenuItemById = (id) => api.get(`/menuItem/${id}`);
export const getAllMenuItemsByRestaurantId = (restaurantId) =>
  api.get(`/menuItems/${restaurantId}`);
//restaurantMenus
export const getRestaurantAll = (restaurantId) =>
  api.get(`/restaurants/${restaurantId}`); // old
//user
export const createOrUpdateUser = (payload) => api.post("/user", payload);
export const getUserByGoogleId = (googleId) => api.get(`/user/${googleId}`);

//like
export const createOrDestroyLike = (payload) => api.post("/like", payload);
export const getUserLikesByRestaurant = (restaurantId, userGoogleId) =>
  api.get(`/like/${restaurantId}/${userGoogleId}`);

//bookmark
export const createOrDestroyBookmark = (payload) =>
  api.post("/bookmark", payload);
export const getUserBookmarksByRestaurant = (restaurantId, userGoogleId) =>
  api.get(`/bookmark/${restaurantId}/${userGoogleId}`);

//comment
export const insertComment = (payload) => api.post(`/comment`, payload);
export const updateCommentById = (id, payload) =>
  api.put(`/comment/${id}`, payload);
export const getCommentsByMenuItemId = (id) => api.get(`/comment/${id}`);
export const deleteCommentById = (id) => api.delete(`/comment/${id}`);

const apis = {
  //restaurant
  insertRestaurant,
  getAllRestaurants,
  updateRestaurantById,
  deleteRestaurantById,
  // getRestaurantById,
  getRestaurantById,
  //menu
  insertMenu,
  getAllMenus,
  updateMenuById,
  deleteMenuById,
  getMenuById,
  getAllMenusByRestaurantId,
  //menuSection
  insertMenuSection,
  getAllMenuSections,
  updateMenuSectionById,
  deleteMenuSectionById,
  getMenuSectionById,
  getAllMenuSectionsByRestaurantId,
  //menuItem
  insertMenuItem,
  getAllMenuItems,
  updateMenuItemById,
  deleteMenuItemById,
  getMenuItemById,
  //restaurantMenu
  getAllMenuItemsByRestaurantId,
  getRestaurantAll,
  //user
  createOrUpdateUser,
  getUserByGoogleId,
  //like
  createOrDestroyLike,
  getUserLikesByRestaurant,
  //bookmark
  createOrDestroyBookmark,
  getUserBookmarksByRestaurant,
  //comment
  insertComment,
  updateCommentById,
  getCommentsByMenuItemId,
  deleteCommentById,
};

export default apis;
