import React from "react";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import SearchIcon from "@mui/icons-material/Search";
import { makeStyles } from "tss-react/mui";
import RestaurantSearch from "./RestaurantSearch";

const useStyles = makeStyles()((theme) => ({
  grow: {
    flexGrow: 1,
  },
  title: {
    display: "none",
    [theme.breakpoints.up("sm")]: {
      display: "block",
    },
  },
  search: {
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: "#ccc",
    "&:hover": {
      backgroundColor: "#ccc",
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.spacing(3),
      width: "auto",
    },
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: "100%",
    position: "absolute",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  nav: {
    background: "#2ba673",
    color: "#000",
  },
}));

export default function PrimarySearchAppBar() {
  const { classes } = useStyles();

  return (
    <div className={classes.grow}>
      <AppBar position="static">
        <div className={classes.nav}>
          <Toolbar>
            <div
              onClick={() => {
                window.location.href = `/`;
              }}
              style={{ cursor: "pointer" }}
            >
              <Typography className={classes.title} variant="h6" noWrap>
                Simple Food Finder
              </Typography>
            </div>
            <div className={classes.search}>
              <div className={classes.searchIcon}>
                <SearchIcon />
              </div>
              <div style={{ paddingLeft: "40px" }}>
                <RestaurantSearch />
              </div>
            </div>
            <div className={classes.grow} />
          </Toolbar>
        </div>
      </AppBar>
    </div>
  );
}
