import React from "react";
import Store from "../components/Store";
import "bootstrap/dist/css/bootstrap.min.css";
import App from "./App";

function Index() {
  return (
    <div className="Index">
      <Store>
        <App />
      </Store>
    </div>
  );
}

export default Index;
