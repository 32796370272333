import React from "react";
import { makeStyles } from "tss-react/mui";
import FavoriteIcon from "@mui/icons-material/Favorite";
import BookmarkIcon from "@mui/icons-material/Bookmark";
import { blue, pink } from "@mui/material/colors";
import { Link } from "react-router-dom";

const useStyles = makeStyles()(() => ({
  root: {
    padding: "3em",
  },
}));

export default function HomePage() {
  const { classes } = useStyles();

  return (
    <div>
      <div className={classes.root}>
        <h3> Integrated menus for the modern era. No more PDFs! </h3>
        <p>
          PDFs are difficult for search engines, hard to view on mobile, and
          difficult to update.{" "}
        </p>
        <h4> Menu features: </h4>
        <p> Filter menu items to find what you want.</p>
        <p> {"Filter out what you don't want!"}</p>
        <p>Less scrolling: Use the tabbed menus to look around.</p>
        <p>
          Save your favorites and bookmark items you are curious about for
          later.
        </p>
        <p>
          Like your favorite options for next time you visit
          <FavoriteIcon style={{ color: pink[500] }} />
        </p>
        <p>
          Bookmark items you want to try next time!
          <BookmarkIcon style={{ color: blue[500] }} />
        </p>
        <h4> {"Here's a link to a demo"} </h4>
        <Link to="/restaurants/Morning Glory Cafe" className="nav-link">
          Demo
        </Link>
        {/* Create a new restaurant: */}
        {/* <CreateDialog form={<RestaurantsInsert />} title="Add restaurant" /> */}
      </div>
    </div>
  );
}
