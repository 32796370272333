import React from "react";
import { Chip } from "@mui/material";
import { Input } from "semantic-ui-react";

const SearchComponent = ({
  searchInput,
  handleChangeFilter,
  handleKeyDownFilter,
  searchChips,
  handleSearchChipClick,
  handleSearchChipDelete,
  label,
  placeholder,
}: any) => (
  <>
    <Input
      size="large"
      name="menuSearch"
      placeholder={placeholder}
      value={searchInput || ""}
      onChange={handleChangeFilter}
      onKeyDown={handleKeyDownFilter}
      label={label}
    />
    {searchChips.map((chip: any) => (
      <Chip
        key={chip.value}
        label={chip.value}
        onClick={() => handleSearchChipClick(chip)}
        onDelete={() => handleSearchChipDelete(chip)}
      />
    ))}
  </>
);
export default SearchComponent;
