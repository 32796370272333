import { AnyAction, configureStore, ThunkDispatch } from "@reduxjs/toolkit";
import thunk from "redux-thunk";
import { reducer as searchReducer } from "./search";

const store = configureStore({
  reducer: {
    search: searchReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: false }).concat(thunk),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export type AppThunkDispatch = ThunkDispatch<RootState, void, AnyAction>; // Export AppThunkDispatch type

export default store;
