import React, { useState, useEffect, useContext } from "react";
import api from "../api";

import { makeStyles } from "tss-react/mui";
import { UserContext } from "./Store";

import { Tab, Tabs, Paper } from "@mui/material";
import { IMenu, IRestaurant, SearchChip } from "../interfaces/Restaurant";
import { useParams } from "react-router-dom";
import MenuSearch from "./MenuSearch";
import MenuComponent from "./MenuComponent";
import useGlobalSearch from "../hooks/UseGlobalSearch";
import { useSelector } from "react-redux";
import { getSearchChips } from "../redux/search";

const useStyles = makeStyles()(() => ({
  root: {
    flexGrow: 1,
    padding: 20,
  },
  image: {
    width: 128,
    height: 128,
  },
  img: {
    margin: "auto",
    display: "block",
    maxWidth: "100%",
    maxHeight: "100%",
  },
  filter: {
    background: "#fbfbf8",
    padding: "10px 20px 10px 20px",
    margin: "10px 20px 10px 20px",
    borderRadius: "4px",
  },
  loading: {
    margin: "10px",
  },
  tabs: {
    backgroundColor: "#fbfbf8",
  },
  menu: {
    padding: "0px 0px 0px 0px",
    boxShadow: "none",
  },
  activetab: {
    fontWeight: "bold",
    color: "#000",
  },
  notactivetab: {
    color: "#666",
  },
  wrappermini: {},
  chips: {
    display: "flex",
    flexGrow: 1,
  },
  floaty: {
    background: "white",
    position: "sticky",
    top: 0,
    bottom: 0,
    paddingTop: "10px",
    paddingBottom: "0px",
    zIndex: 5,
    boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
  },
}));

interface RestaurantMenuTabsProps {
  restaurant: IRestaurant;
}

function RestaurantMenuTabs({ restaurant }: RestaurantMenuTabsProps) {
  const { classes } = useStyles();
  const { restaurantId } = useParams();

  const [user] = useContext(UserContext);

  const [activeTabIndex, setActiveTabIndex] = useState("");
  const [likesDict, setLikesDict] = useState({});
  const [bookmarksDict, setBookmarksDict] = useState({});

  const searchChips = useSelector(getSearchChips);
  const searchChipsString = searchChips.map((chip) => chip.value).join(" ");
  const restaurantWithRender = useGlobalSearch(searchChipsString, restaurant);

  useEffect(() => {
    async function fetchLikes() {
      const likesByUser = await api.getUserLikesByRestaurant(
        restaurantId,
        user.google.googleId
      );
      const dict: any = {};
      const likes = likesByUser.data.data;
      likes.forEach((like: any) => {
        dict[like.itemId] = true;
      });
      setLikesDict(dict);
    }
    async function fetchBookmarks() {
      const bookmarksByUser = await api.getUserBookmarksByRestaurant(
        restaurantId,
        user.google.googleId
      );
      const dict: any = {};
      const bookmarks = bookmarksByUser.data.data;
      bookmarks.forEach((bookmark: any) => {
        dict[bookmark.itemId] = true;
      });
      setBookmarksDict(dict);
    }
    if (user && restaurant) {
      Promise.all([fetchLikes(), fetchBookmarks()]);
    }
  }, [user, restaurant, restaurantId]);

  useEffect(() => {
    if (restaurant && restaurant.menus && restaurant?.menus?.length > 0) {
      setActiveTabIndex(restaurant.menus[0].name);
    }
  }, [restaurant]);

  //tabs
  const handleChangeTabs = (event: any, value: string) => {
    setActiveTabIndex(value);
  };

  return (
    <>
      <Paper className={classes.menu}>
        <>
          <div className={classes.floaty}>
            <Tabs
              className={classes.tabs}
              value={activeTabIndex}
              onChange={(e, val) => {
                handleChangeTabs(e, val);
              }}
              indicatorColor="secondary"
              centered
            >
              {restaurant?.menus?.map((menu) => (
                <Tab
                  className={
                    activeTabIndex === menu.restaurantId
                      ? classes.activetab
                      : classes.notactivetab
                  }
                  key={menu.name}
                  value={menu.name}
                  label={menu.name}
                />
              ))}
            </Tabs>
            <MenuSearch />
          </div>
          {restaurantWithRender?.menus?.map((menu: IMenu) => (
            <MenuComponent
              restaurant={restaurantWithRender}
              key={menu.name}
              menu={menu}
              likesDict={likesDict}
              bookmarksDict={bookmarksDict}
              activeTabIndex={activeTabIndex}
            />
          ))}
        </>
      </Paper>
    </>
  );
}

export default RestaurantMenuTabs;
